import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "./sass/app.sass"
import Maska from 'maska'
import VueYandexMetrika from 'vue-v3-yandex-metrika'
import QrReader from 'vue3-qr-reader';
import moment from 'moment'

const app = createApp(App);
app.config.globalProperties.$moment = moment;

	app
	.use(Maska)
	.use(store)
	.use(router)
	.use(QrReader)
	.use(VueYandexMetrika, {
		id: 90573697,
		router: router,
		env: process.env.NODE_ENV
	})
	.mount('#app')

