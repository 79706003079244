
import {defineComponent} from "vue";
import {mapGetters} from "vuex";
import HomeOperations from "@/views/Home/HomeOperations.vue";
import PayPopup from "@/views/Home/PayPopup.vue";
import store from "@/store";
import GCard from "@/components/Base/GCard.vue";
import GText from "@/components/Base/GText.vue";
import UserApi from "@/api/UserApi";
import HomeQRCode from "@/views/Home/HomeQRCode.vue";

export default defineComponent({
    components: {HomeQRCode, GText, GCard, PayPopup, HomeOperations},
    computed: {
        user() {
            return store.state.auth
        },
        ...mapGetters(['auth', 'balance'])
    },
    data() {
        return {
            showPayPopup: false,
            showQRcode: false,
            terminal_code: '' as string,
            terminal_balance: 0,
            can_stop: false,
            city: '',
            address: '',
            needOperationsReload: false
        }
    },
    created() {
        this.terminal_balance = this.balance
        this.lastStartWash()
    },
    watch: {
        'user.wash_start': function () {
            this.lastStartWash()
        }
    },
    methods: {
        openPay() {
            this.showPayPopup = true
        },
        washStart() {
            if (confirm(`Вы уверены, что хотите начать мойку, пост ${this.terminal_code} ?`)) {
                UserApi.startWash(this.terminal_code, this.terminal_balance)
                    // .then(() => {
                    //     store.dispatch('getUserData')
                    // })
                    .catch(error => {
                        if(error?.response?.data?.msg) {
                            alert(error.response.data.msg)
                        }
                    })
                    .finally(() => {
                        store.dispatch('getUserData')
                    })
            }
        },
        washStop() {
            if (confirm(`Вы уверены, что хотите остановить мойку?`)) {
                UserApi.stopWash()
                    .then(response => {
                        alert(response.data?.text)
                        store.dispatch('getUserData')
                    })
                    .catch(error => {
                        if (error?.response?.data?.text) {
                            alert(error.response.data.text)
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {
                            store.dispatch('getUserData')
                            this.needOperationsReload = !this.needOperationsReload
                        }, 3000)
                    })
            }
        },
        payOnline() {
            // const checkout = new API('815490');
            // console.log(checkout.token)
        },
        logout (): void {
            store.dispatch('logout')
        },
        closeQRcode() {
            this.showQRcode = false
            store.dispatch('getUserData')
        },
        lastStartWash() {
            if (this.user?.wash_start) {
                UserApi
                    .lastStartWash()
                    .then((response) => {
                        this.can_stop = response.data.can_stop
                        this.city = response.data.city
                        this.address = response.data.address
                    })
            }
        }
    }
})
