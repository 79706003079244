
import {defineComponent} from "vue";

import UserApi from "@/api/UserApi";
import {BASE_URL, httpClient} from "@/api/httpClient";
import router from "@/router";
import GCard from "@/components/Base/GCard.vue";

export default defineComponent({
    components: {GCard},
    data() {
        return {
            phone: '+7 ',
            code: '',
            error: {
                show: false,
                msg: 'Ошибка авторизации'
            },
            seconds_to_send: 0
        }
    },
    created() {
        this.startTimer()
    },
    methods: {
        startTimer() {
            setInterval(() => {
                if (this.seconds_to_send > 0) {
                    this.seconds_to_send--;
                }
            }, 1000);
        },
        resetError() {
            this.error.show = false
            this.error.msg = 'Укажите номер телефона'
        },
        getSmsCode() {
            this.error.show = false
            let phone = this.getPhone()
            if (phone.length === 11) {
                httpClient.get(`${BASE_URL}get-auth-code`, {
                    params: {
                        phone: phone
                    }
                })
                    .then(response => {
                        if (response.data.status_code === 409) {
                            let sec = response.data.data.msg.replace('Повторная отправка возможна через ', '').replace(' сек.', '')
                            this.seconds_to_send = parseInt(sec)
                        } else if (response.data.seconds_to_send) {
                            this.seconds_to_send = response.data.seconds_to_send
                        }

                        alert('Ожидайте смс сообщения с кодом')
                    })
                    .catch(error => {
                        if (error?.response?.data?.msg) {
                            let sec = error.response.data.msg.replace('Повторная отправка возможна через ', '').replace(' сек.', '')
                            this.seconds_to_send = parseInt(sec)
                        } else {
                            alert('Ошибка отправки СМС')
                        }
                    })
            } else {
                this.error.show = true
                this.error.msg = 'Укажите номер телефона'
            }
        },
        submit() {
            this.error.show = false
            let phone = this.getPhone()

            if (this.code) {
                UserApi.auth(phone, this.code)
                    .then(response => {
                        let token = response.data.data.token_type + " " +response.data.data.token
                        localStorage.setItem('token', token)
                        httpClient.defaults.headers.common.Authorization = token

                        router.push({ name: 'home.index' })
                    })
                    .catch(error => {
                        if (error?.response?.data?.msg) {
                            this.error.msg = error.response.data.msg
                        }
                        this.error.show = true
                    })
            }
        },
        getPhone(): string {
            return  this.phone
                .replaceAll(' ', '')
                .replaceAll('+', '')
                .replaceAll('-', '')
                .replaceAll('(', '')
                .replaceAll(')', '')
        }
    }
})
