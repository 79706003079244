import {createRouter, createWebHistory, RouteRecordRaw, RouterOptions} from 'vue-router'

import store from "@/store";
import LoginView from "@/views/LoginView.vue";
import HomeIndex from "@/views/Home/HomeIndex.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: '/login',
		name: 'login.view',
		component: LoginView,
		meta: { guest: true }
	},
	{
		path: '',
		name: 'home',
		component: HomeIndex,
		redirect: {name: 'home.index'},
		children: [
			{
				path: 'home',
				name: 'home.index',
				component: HomeIndex
			}
		]
	}
]

const router = createRouter(<RouterOptions>{
	mode: 'history',
	history: createWebHistory(process.env.BASE_URL),
	routes: routes
})

router.beforeEach(async (to, from, next) => {
	if (!store.state.auth && localStorage.getItem('token')) await store.dispatch('getUserData')
	if (to.meta.guest) {
		next()
	} else {
		if (store.state.auth) {
			next()
		} else {
			next({ name: 'login.view' })
		}
	}
})


export default router
