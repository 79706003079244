
import {defineComponent} from "vue";
import BasePopup from "@/components/Base/BasePopup.vue";
import { QrStream } from 'vue3-qr-reader';
import UserApi from "@/api/UserApi";
import {mapGetters} from "vuex";
import store from "@/store";



export default defineComponent({
    components: { BasePopup, QrStream },
    computed: {
        ...mapGetters(['balance'])
    },
    props: {
        prop_terminal_balance: {
            type: Number
        }
    },
    data() {
        return {
            qr_code: '',
            terminal_balance: this.prop_terminal_balance as number
        }
    },
    methods: {
        onDecode(data: string) {
            if (data.includes('ECOcw')) {
                this.qr_code = data
            }
        },
        close() {
            this.$emit('close');
        },
        washStart() {
            UserApi.startWash(this.qr_code, this.terminal_balance)
                .then(response => {
                    this.close()
                })
                .catch(error => {
                    if(error?.response?.data?.msg) {
                        alert(error.response.data.msg)
                    }
                })
                .finally(() => {
                    store.dispatch('getUserData')
                })
        },
    }
})
