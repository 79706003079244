
import {defineComponent} from "vue";
import BasePopup from "@/components/Base/BasePopup.vue";
import {BASE_URL, httpClient} from "@/api/httpClient";
import {mapGetters} from "vuex";
import GText from "@/components/Base/GText.vue";

export default defineComponent({
    components: {GText, BasePopup},
    data() {
        return {
            amount: 0,
            loadForm: false,
            checkout: {} as any
        }
    },
    computed: {
        ...mapGetters(['min_replenish', 'auth'])
        // ...mapGetters(['auth'])
    },
    methods: {
        close() {
            if (this.loadForm)
                this.checkout.destroy()

            this.$emit('close')
        },
        getPayUrl() {
            if (this.amount >= this.min_replenish) {
                httpClient.post(`${BASE_URL}user/replenish-token`, {
                    pay_type: 'site2',
                    amount: this.amount,
                    email: this.auth.email
                })
                    .then(response => {
                        if (response.data.confirmation_token) {
                            this.loadForm = true
                            this.checkout = new window.YooMoneyCheckoutWidget({
                                confirmation_token: response.data.confirmation_token, //Токен, который перед проведением оплаты нужно получить от ЮKassa
                                return_url: 'https://lk.myeco24.ru/home', //Ссылка на страницу завершения оплаты
                                error_callback: function (e:any) {
                                    alert('Произошла ошибка, попробуйте еще раз!')
                                    console.log(e)
                                }
                            });
                            this.checkout.render('payment-form')
                        }
                    })
            }
        }
    }
})
