
import {defineComponent, PropType} from "vue";

export default defineComponent({
    emits: ['update:modelValue'],
    props: {
        label: {
            type: String,
            require: false
        },
        fromText: {
            type: String,
            require: false
        },
        max: {
            type: Number,
            require: false
        },
        modelValue: {
        },
        errors: {
            type: Array,
        },
        placeholder: {
            type: String,
            require: false
        },
        required: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'text'
        }
    },
    watch: {
        'modelValue': function (value) {
            this.value = value
        },
        'value': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$emit('update:modelValue', newVal)
            }
        }
    },
    data() {
        return {
            value: this.modelValue
        }
    }
})
