
import {defineComponent} from "vue";
import UserApi from "@/api/UserApi";
import {Operation} from "@/api/Models";
import GCard from "@/components/Base/GCard.vue";

export default defineComponent({
    components: {GCard},
    props: {
        needReload: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    created() {
        this.loadOperations()
    },
    data() {
        return {
            operations: [] as Array<Operation>,
            to: 0,
            total: 0,
            page: 0,
            types: {
                replenish_online: 'Пополнение онлайн',
                cashback: 'Кэшбэк',
                waste: 'Списание',
            }
        }
    },
    watch: {
        needReload: function () {
            this.operations = []
            this.page = 0
            this.loadOperations();
        }
    },
    methods: {
        loadOperations() {
            this.page++
            UserApi.operations({
                page: this.page,
                qty: 5
            })
                .then(response => {
                    this.operations = this.operations.concat(response.data.data)
                    this.to = response.data.to
                    this.total = response.data.total
                })
        }
    }
})
