import {ApiResponse, Operation, OperationFilter} from "@/api/Models";
import {BASE_URL, httpClient} from "@/api/httpClient";

class UserApi {
	sendSMS(): Promise<ApiResponse<any>> {
		return httpClient.get(`${BASE_URL}`)
	}
	
	auth(phone:string, code:string): Promise<ApiResponse<any>> {
		return httpClient.post(`${BASE_URL}login`, {
			phone: phone,
			code: code
		})
	}

	me(): Promise<ApiResponse<any>> {
		return httpClient.get(`${BASE_URL}user`)
	}
	
	operations(data: OperationFilter): Promise<ApiResponse<{
		data: Array<Operation>,
		to: number,
		total: number,
	}>> {
		return httpClient.get(`${BASE_URL}user/operations`, {
			params: data
		})
	}
	
	startWash(code: string, terminal_balance: number): Promise<ApiResponse<any>> {
		return httpClient.post(`${BASE_URL}start-wash`, {
			code: code,
			terminal_balance: terminal_balance
		})
	}
	
	stopWash(): Promise<ApiResponse<any>> {
		return httpClient.post(`${BASE_URL}stop-wash`)
	}
	
	lastStartWash() : Promise<ApiResponse<any>>{
		return httpClient.get(`${BASE_URL}last-start-wash`)
	}
}

export default new UserApi();
