import { createStore } from 'vuex'
import {State, User} from "@/api/Models";
import UserApi from "@/api/UserApi";
import {httpClient} from "@/api/httpClient";
import router from "@/router";

export default createStore({
	state: {
		auth: null as null | User,
		min_replenish: 0,
		errors: [],
	},
	getters: {
		auth: (state) => state.auth,
		balance: (state) => state.auth?.balance ? state.auth?.balance / 100 : 0,
		min_replenish: (state) => state.min_replenish,
		errors: (state) => state.errors,
	},
	mutations: {
		setUser (state:State, user: User) {
			state.auth = user
		},
		set_min_replenish (state:State, min_replenish: number) {
			state.min_replenish = min_replenish
		},
		addError (state:State, error:any) {
			state.errors.push({
				url: error.response?.request?.responseURL ?? '',
				status: error.response?.status ?? '',
				statusText: error.response?.statusText ?? '',
				message: error.response?.data?.message || error.message
			})
		},
	},
	actions: {
		auth ({ commit }, payload: {
			phone: string,
			code: string
		}) {
			UserApi.auth(payload.phone, payload.code)
				.then(response => {
					localStorage.setItem('token', response.data.data.token)
					httpClient.defaults.headers.common.Authorization = response.data.data.token
					
					router.push({ name: 'home.index' })
				})
				.catch(error => {
					commit('addError', error)
				})
		},
		async getUserData ({ commit, state }) {
			await UserApi.me()
				.then(response => {
					commit('setUser', response.data.data)
					commit('set_min_replenish', response.data.min_replenish)
				})
				.catch((error) => {
					commit('addError', error)
				})
		},
		logout ({ commit }) {
			localStorage.removeItem('token')
			
			router.push({ name: 'login.view' }).then(() => {
				commit('setUser', null)
			})
		},
	},
	modules: {
	}
})
