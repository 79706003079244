import Axios from 'axios'

const token:string = localStorage.getItem('token') || ''
export const BASE_URL = '/api/'


export const httpClient = Axios.create({
	baseURL: process.env.VUE_APP_BASE_API_PROTOCOL + '://' + process.env.VUE_APP_BASE_API_URL,
	headers: {
		'X-Requested-With': 'XMLHttpRequest',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Credentials': true,
		'Access-Control-Allow-Methods': '*'
	}
})



if (token) {
	httpClient.defaults.headers.common.Authorization = token
}
