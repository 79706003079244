import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { class: "form-label" }
const _hoisted_3 = ["required", "placeholder", "type", "max"]
const _hoisted_4 = {
  key: 0,
  class: "invalid-feedback"
}
const _hoisted_5 = {
  key: 1,
  class: "form-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _withDirectives(_createElementVNode("input", {
      class: _normalizeClass(["form-control", {'is-invalid': _ctx.errors}]),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      required: _ctx.required,
      placeholder: _ctx.placeholder,
      type: _ctx.type,
      max: _ctx.max
    }, null, 10, _hoisted_3), [
      [_vModelDynamic, _ctx.value]
    ]),
    (_ctx.errors)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.errors.join(', ')) + ". ", 1))
      : _createCommentVNode("", true),
    (_ctx.fromText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.fromText), 1))
      : _createCommentVNode("", true)
  ]))
}